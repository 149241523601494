// $darkBlack: #282c35;
@import '../_vars.scss';
$darkBlack: #000;
$nightWhite: hsla(0,0%,98%,1);
$borderWidth: 3px;

.site-content.pita {
  transition: background-color 0.3s;
}
.cart.minimized {
  transition: background-color 0.3s;
}
.pages-nav {
  transition: background-color 0.3s;
  display: flex;
  flex-wrap: warp;
  align-items: center;
  ul {
    flex: 1;
  }
  .react-toggle {
    margin-right: 0.5em;
  }
}
.site-content.pita.dark {
  color: $nightWhite;
  background-color: $darkBlack;
  // background-color: #222222;
  // background-color: #181818;
  // background-color: #0c273a;
  // background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23153851' fill-opacity='0.51' fill-rule='evenodd'%3E%3Cpath d='M8 0v20L0 10M16 0v10L8 0M16 10v10H8'/%3E%3C/g%3E%3C/svg%3E");
  background-image: none;
  transition: background-color 0.3s;
}
.pita.dark {
  a.address-link {
    color: $nightWhite;
  }
  a.checkout-button.button {
    background: $red;
    text-transform: capitalize;
    color: $nightWhite;
    font-weight: 600;
  }

  .cart.minimized {
    color: $nightWhite;
    background: $darkBlack;
    // border-bottom: 2px solid $nightWhite;
  }
  a.page-link {
    font-weight: 700;
    color: $nightWhite;
    border-bottom: $borderWidth solid $darkBlack;
    &:hover, &.active {
      color: $nightWhite;
      border-bottom: $borderWidth solid $red;
    }
  }
  .pages-nav {
    color: $nightWhite;
    background: $darkBlack;
  }
  .quick-links {
    background: $darkBlack;
    color: $nightWhite;
  }
  .menu-features {
    a.navItem {
      background: black;
    }
  }
}



.pita.dark {
  .landing-page {
    // background-image: url(https://images.unsplash.com/photo-1542319920155-a236a55f1a97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50);
    // background-position: 0% 50%;

  }


  a.page-link {
    color: $nightWhite;
    border-bottom-color: $darkBlack;
    &:hover, &.active {
      border-bottom-color: $nightWhite;
    }
  }

  .landing-page {
    .text {
      color: $nightWhite;
      background: rgba(0,0,0,0.7);
    }
  }


}
$darkCheckoutPaneColor: #185500;
.dark {
  .menu-story .category-name .text {
    color: $nightWhite;
    background: none;
  }
  .category__header {
    border-bottom: 1.5px solid white;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .cart-type-container {
    background: $darkCheckoutPaneColor;
    color: $nightWhite;
  }

  .checkout__cash-payment {
    color: $nightWhite;
    background: $darkCheckoutPaneColor;
  }
  .price-summary {
    color: $nightWhite;
  }
  .switch-button-group, .cart-type-container {
    color: $nightWhite;
    label.selected {
      background: $darkCheckoutPaneColor;
    }
    label.not-selected {
      background: #8bb487;
    }
  }
  .place-order-section {
    background: $darkCheckoutPaneColor;
    .place-order-button {
      background: #16b7ff;
    }
  }
  .footer {
    background: #111;
  }
}
